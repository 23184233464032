import * as React from "react";
import { Link } from "gatsby";
import { withLayout, LayoutProps } from "../components/Layout";
import chevronDown from "../css/svg/chevron-down.svg";
import { style, classes } from "./home.st.css";

const IndexPage = (props: LayoutProps) => {
  return <div className={style(classes.root)}>
    <div className={style(classes.splashScreen)}>
      <div className={style(classes.splashScreenContent)}>
        <div className={style(classes.splashScreenPoem)}>
          <p>כָּל הַמִּלִּים הַבָּאוֹת</p>
          <p>הַבִּטּוּי הַמְּדֻיָּק הַבָּא</p>
          <p>חֵלֶק מֵהַמִּלִּים הַבָּאוֹת</p>
          <p>לְלֹא הַמִּלִּים הַבָּאוֹת</p>
        </div>
        <div className={style(classes.poemHeader)}>
          <span className={style(classes.divider)}>
            <img src={chevronDown} width={16} height={10} />
          </span>
          <span className={style(classes.details)}>
            <span className={style(classes.title)}>שאילתה ממוקדת</span>
            <span className={style(classes.divider)}>&nbsp;/&nbsp;</span>
            <span className={style(classes.author)}>תומר ליכטש</span>
          </span>
          <span>מתוך הספר <Link to={`/typed-poems/`}>
            <span className={style(classes.source)}>שירים טיפוסיים</span></Link>
          </span>
        </div>
      </div>
    </div>
  </div>;
};

export default withLayout(IndexPage);
